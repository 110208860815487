<template>
    <div class="base-page">
        <div class="query-box">
            <el-form :inline="true" :model="query_form"  ref="query_form" class="demo-form-inline">

                <el-form-item label="查询对象" prop="type" >
                    <el-select v-model="query_form.type" placeholder="请选择"  size="mini" style="width: 100px" >
                        <el-option
                                v-for="item in type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="query_form.type==1">
                    <el-form-item  prop="order_channel" >
                        <el-select v-model="query_form.select_build_ids" multiple collapse-tags  placeholder="请选择楼宇"  size="mini" style="width: 200px">
                            <el-option
                                    v-for="item in query_form.buildArr"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <template v-if="query_form.type==2">
                    <el-form-item  prop="order_channel">
                        <el-select v-model="query_form.select_unit_ids" multiple collapse-tags placeholder="请选择单元"  size="mini" style="width: 200px">
                            <el-option
                                    v-for="item in query_form.unitArr"
                                    :key="item.unit_id"
                                    :label="item.all_name"
                                    :value="item.unit_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <template v-if="query_form.type==3">
                    <el-form-item  prop="order_channel">
                       <el-input v-model="query_form.room_code" placeholder="请输入房屋编号" size="mini"></el-input>
                    </el-form-item>
                </template>


                <el-form-item label="费用期间" prop="date_between" >
                    <el-date-picker
                            size="mini"
                            v-model="query_form.month_between"
                            type="monthrange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                            @change="monthChang"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item  label="收费项目">
                    <el-select v-model="query_form.select_items_ids" multiple collapse-tags placeholder="请选择收费项目"  size="mini" >
                        <el-option
                                v-for="item in query_form.chargeItemArr"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button size="mini" type="primary" @click="query">查询</el-button>
                </el-form-item>



            </el-form>


        </div >


        <div class="base-body" id="base-body">

            <div class="base-table" >
                <el-button size="small" style="float: right" >下载当前页缴费通知单</el-button>
                <el-table
                        :data="dataList"
                        border
                        :height="tableHeight"
                        v-loading="loading"
                        ref="table"

                >

                    <el-table-column type="expand" >
                        <template slot-scope="props">
                            <el-table :data="props.row.info" v-loading="props.row.loading">
                                <el-table-column label="收费项目" prop="" ></el-table-column>
                                <el-table-column label="收费项目" prop="" ></el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>

                    <el-table-column label="房屋编号" prop="roo_code" ></el-table-column>
                    <el-table-column label="房屋编号" prop="roo_code"></el-table-column>
                    <el-table-column label="房屋编号" prop="roo_code" ></el-table-column>

                </el-table>
            </div>
        </div>

        <div class="base-footer">
            <el-pagination @current-change="handleCurrentChange"
                           @size-change = "handSizeChange"
                           :current-page="page"
                           :page-sizes="[10,20,50]"
                           :page-size="limit"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="count"
            >
            </el-pagination>
        </div>


    </div>




</template>

<script>

    export default {
        name: "main_page",
        props:['upPageParams'],
        components:{

        },
        data() {
            return {

                loading:false,
                page:1,
                limit:20,
                count:0,

                query_form:{

                    //查询对象
                    type:'1',//默认按楼宇
                    buildArr:[],//楼宇数据
                    unitArr:[],//单元数据
                    chargeItemArr:[],//小区收费项目
                    select_build_ids:[],//选择的楼宇，默认全部
                    select_unit_ids:[],//选择的单元，默认全部
                    select_items_ids:[],//选择的收费项目，默认全部
                    room_code:'',//输入的房屋编号

                    //费用期间
                    month_between:[],
                    start_month:'',
                    end_month:''

                },


                type_options: [ {
                    value: '1',
                    label: '按楼宇'
                }, {
                    value: '2',
                    label: '按单元'
                }, {
                    value: '3',
                    label: '按房屋'
                }],


                tableHeight: 0,
                dataList:[],


            }
        },
        created() {
            this.getBuildList()
            this.getUnitList()
            this.getItemList()
        },
        mounted() {

            this.$nextTick(function() {
                this.tableHeight = document.getElementById("base-body").offsetHeight - 35;
            })
        },
        methods:{

            //获取楼宇
            getBuildList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuild/getList',params).then((res)=>{
                    if (res.code ==1){

                        this.query_form.buildArr = res.data.list
                        //默认全选
                        if (res.data.list.length > 0){

                            res.data.list.forEach((item)=>{
                                this.query_form.select_build_ids.push(item.id);
                            })
                        }
                    }

                })
            },
            //获取单元列表
            getUnitList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuildUnit/getBuildUnitListByResidence',params).then((res)=>{
                    if (res.code ==1){
                        this.query_form.unitArr = res.data

                        //默认全选
                        if (res.data.length > 0){

                            res.data.forEach((item)=>{
                                this.query_form.select_unit_ids.push(item.unit_id);
                            })
                        }
                    }
                })
            },
            //小区下收费项目
            getItemList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._postApi('/wy/chargeItem/getItemListByResidence',params).then((res)=>{
                    if (res.code ==1){
                        this.query_form.chargeItemArr = res.data

                        //默认全选
                        if (res.data.length > 0){

                            res.data.forEach((item)=>{
                                this.query_form.select_items_ids.push(item.id);
                            })
                        }
                    }
                })
            },


            //费用期间变化
            monthChang(){
                if (this.query_form.month_between){
                    this.query_form.start_month = this.dayjs(this.query_form.month_between[0]).format("YYYY-MM")
                    this.query_form.end_month = this.dayjs(this.query_form.month_between[1]).format("YYYY-MM")
                }else {
                    this.query_form.start_month = ''
                    this.query_form.end_month  = ''
                }

            },


            //page切换
            handleCurrentChange(val) {
                this.page = val;
                this.query();
            },
            //limit切换
            handSizeChange(val){

                this.limit = val;
                this.query();
            },

            //查询
            query(){

                if (this.query_form.start_month=='' || this.query_form.end_month==''){
                    this.$alert('请选择费用期间范围', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {

                    });
                    return false
                }


                let params = {
                    residence_id:this.upPageParams.commiunity.id,
                    type:this.query_form.type,

                    cost_month_start:this.query_form.start_month,
                    cost_month_end:this.query_form.end_month,
                    item_ids:this.query_form.select_items_ids
                }
                //楼栋、单元、房屋
                if (params.type==1){
                    params.ids = this.query_form.select_build_ids
                }else if (params.type ==2) {
                    params.ids = this.query_form.select_unit_ids
                }else {
                    params.ids = [this.query_form.room_code]
                }


                this.loading = true
                this._postApi('/wy/callNotice/list', params).then((res) => {
                    this.loading = false

                    if (res.code == 1) {
                        this.dataList = res.data;
                    } else {

                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                        }).catch(() => {

                        });
                    }
                }).catch((err) => {
                    this.loading = false
                })


            },


            closeEvent(e) {
                if (e.type == 'close') {
                    this.dialog_order_info.open = false;
                }
            },

        }
    }
</script>

<style scoped>
    .query-box{
        padding: 15px;
        display: flex;flex-direction: row;
    }
    .base-page{
        width: 100%;height: 100%;
        display: flex;flex-direction: column;
    }

    .base-body{
        flex: 1;width: 100%;
    }

    .base-table{
        padding: 0 15px;
        border-radius: 5px;
        background: #ffffff;
        height: 100%;
        width: 100%;



    }
    .base-footer{
        text-align: right;
        padding: 5px;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        /*color: #99a9bf;*/
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

</style>
